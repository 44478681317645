@import "../../styleSheet/variables";

  .double-button-pop-up-div {
    width: 100%;
    display: flex;
    position: absolute;
    right: 0px;
    bottom: toRem(-40);
    .btn-1 {
      border: none;
      color: $blue;
      background-color: $white;
      height: toRem(44);
      width: 50%;
      font-weight: 700;
      font-size: toRem(16);
    }
    .btn-2 {
        border: toRem(1) solid $blue;
        color: $white;
        background-color: $blue;
        border-radius: 0px;
        height: toRem(44);
        width: 50%;
        font-weight: 700;
        font-size: toRem(16);
    }
  }