@import "./variables";

.submit-header {
  margin-top: toRem(50);
  margin-bottom: toRem(12);
  font-size: toRem(16);
  font-weight: 700;
  line-height: toRem(28);
  color: $header;
}

.submit-header-style {
    font-size: toRem(16);
    font-weight: 700;
    line-height: toRem(28);
    color: $header;
  }

.submit-sub-header {
  margin-bottom: toRem(50);
  font-size: toRem(16);
  font-weight: 400;
  line-height: toRem(28);
  color: $header;
}
