@import "../../styleSheet/variables";

.main-app-div {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.header-app-div {
  height: fit-content;
  width: 100%;
  position: sticky;
  z-index: 999;
  background-color: $white;
}

.content-app-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  flex: 1;
  overflow-y: scroll;
}

@media (max-width: 700px) {
  .content-app-div {
    overflow-y: unset !important;
  }
}
