@import "../../styleSheet/variables";

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}
.div-center-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.step-two-arrow-button {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0px;
  width: 100%;
  margin-bottom: 15px;
  color: #3d515a;
}

.accordion-wrapper {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; 
  border: none;
}
.accordion-wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  border: none;
}

.line-height-20 {
  line-height: 20px;
}

.color-grey {
  color: #68758b;
}

.double-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  .btn-1 {
    border: 1px solid rgb(0, 102, 204);
    color: rgb(0, 102, 204);
    border-radius: 22px;
    height: 34px;
    width: 150px;
    font-weight: bold;
    margin: 10px;
  }
  .btn-2 {
    background-color: rgb(0, 102, 204);
    color: rgb(255, 255, 255);
    border-radius: 22px;
    height: 34px;
    width: 150px;
    font-weight: bold;
    border: unset;
    margin: 10px;
  }
}

.mt-10 {
  margin-top: 10px;
}

.inspection-header {
  position: relative;
  .back-icon {
    position: absolute;
    cursor: pointer;
  }
  div {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.double-button-div {
  width: 100%;
  display: flex;
  margin-top: toRem(40);
  justify-content: space-between;
  .btn-1 {
    border: toRem(2) solid $blue;
    color: $blue;
    background-color: $white;
    border-radius: toRem(22);
    height: toRem(44);
    width: toRem(150);
    font-weight: 700;
    font-size: toRem(16);
  }
  .btn-2 {
    border: toRem(1) solid $blue;
    color: $white;
    background-color: $blue;
    border-radius: toRem(22);
    height: toRem(44);
    width: toRem(150);
    font-weight: 700;
    font-size: toRem(16);
  }
}
