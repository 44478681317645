.slider-content {
  padding: 20px;
  text-align: center;
  background-color: white;
}

.slider-content {
  margin: 0px;
}

.main-slider-wrapper {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  .main-img {
    height: 185px;
    width: 250px;
    margin-bottom: 10px;
  }
  .dlt-img {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}

.slider-video-style {
  height: 150px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.slider-image-style {
  width: 100%;
  display: flex;
  justify-content: center;
}
