@import "./variables";

.sub-headers {
  font-size: toRem(14);
  font-weight: 400;
  line-height: toRem(24);
  color: $grey;
}

.explanation-div {
  margin-top: toRem(20);
  margin-bottom: toRem(15);
  display: flex;
  justify-content: space-between;
}

.explanation {
  font-size: toRem(14);
  font-weight: 400;
  line-height: toRem(24);
  color: $grey-4;
  margin-bottom: toRem(15);
}

.next-button {
  background-color: $blue;
  color: $white;
  width: 100%;
  border: 0px;
  height: toRem(44);
  border-radius: toRem(22);
  margin: toRem(12) 0px;
}
