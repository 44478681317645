@import "./variables";

.button-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loading-component {
  margin-top: toRem(10);
  padding-bottom: toRem(10);
}

.button-contained {
  background-color: $blue;
  color: $white;
  width: 80%;
  border: 0px;
  height: 34px;
  border-radius: 22px;
  margin: 12px 0px;
}

.button-contained-opacity {
  background-color: $blue;
  color: $white;
  width: 80%;
  border: 0px;
  height: 34px;
  border-radius: 22px;
  margin: 12px 0px;
  opacity: 0.5;
}

.tutorial-btn {
  display: flex;
  cursor: pointer;
  div {
    color: $blue;
    margin-left: 5px;
  }
}

.photo-btn {
  margin-top: 20px;
  background-color: #f2f2f2;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mt-20 {
  margin-top: 20px;
}

.mar-v-30 {
  margin-block: 30px;
}

.success-img {
  height: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
}
