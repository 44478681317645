@import "../../../styleSheet/variables";

.margin-ver-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mb-10 {
  margin-bottom: 10px;
}

.submit-review {
  font-weight: bold;
  font-size: larger;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.submit-page-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 36px;
  margin-top: 40px;
}

.text-center {
  text-align: center;
}

.success-text {
  font-weight: bold;
  font-size: 30px;
}

.camera-video-btn-wrap {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.add-asset-text {
  font-size: toRem(16);
  font-weight: 700;
  line-height: toRem(22);
  color: $grey;
}
