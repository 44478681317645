@import "./variables";

.delete-modal {
    padding: 0px;
    margin: 0px;
}

.error-popup {
    border: none;
    color: $white;
    position: absolute;
    left: toRem(0);
    bottom: toRem(-35);
    background-color: $blue;
    height: toRem(44);
    width: 100%;
    font-weight: 700;
    font-size: toRem(16);
  }