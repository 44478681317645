@import "./variable.scss";
@import "../../styleSheet/variables";

//header css starts here

.header-wrapper {
  height: 10vh;
  header {
    height: 10vh;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    background-color: $white;
    // padding: 0px toRem(20);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px -1px,
      rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  }
  .logo-img {
    width: 100px;
  }
  .logo-text {
    border-left: toRem(1) solid $color_gray;
    padding-left: $padding-left-20;
    font-weight: 600;
    font-size: toRem(18);
    flex-grow: calc(1);
    line-height: toRem(27);
    color: $header;
  }
  .menu-icon {
    font-size: toRem(25);
  }
}

.header-wrapper-mobile {
  height: toRem(60);
  width: 100%;
  header {
    height: toRem(60);
    margin-top: 0px;
    width: 100%;
    display: flex;
    justify-content: end;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px -1px,
      rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  }
  .tool-bar {
    width: 95%;
    display: flex;
    justify-content: space-between;
  }
  .logo-img {
    width: toRem(130);
  }
  .menu-icon {
    font-size: toRem(30);
  }
}

//footer css starts here
.footer-links {
  gap: toRem(17);
  a {
    color: $color-white;
    padding-right: $padding-right-10;
  }
}
.footer-img {
  padding-right: toRem(10);
  width: toRem(60);
}
.footer-btn {
  background-color: $background-dark;
  color: $color-white;
  outline: none;
  border: none;
  text-decoration: underline;
  margin-top: 0px;
}
.font-small {
  font-size: toRem(14);
}

.main-footer {
  // height: toRem(90);
  display: flex;
  background-color: $grey-3;
  color: $white;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: toRem(12);
}

@media (max-width: 700px) {
  .main-footer-column {
    position: unset !important;
  }
}

.main-footer-column {
  display: flex;
  background-color: $grey-3;
  color: $white;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: toRem(15);
  button {
    padding: 0px;
  }

  :nth-child(1) {
    justify-content: left !important;
  }
  .links-wrapper {
    display: flex;
    flex-direction: column-reverse;
    border-top: 1px solid;
    margin-top: 10px;
    div {
      margin-left: 0px !important;
      margin-top: 10px;
      flex-direction: column;
      align-items: baseline;
      display: flex;
    }
    .links {
      display: flex;
      flex-direction: column;
      a {
        margin-left: 0px;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }
}

.links,
a {
  font-size: toRem(12);
  margin-left: toRem(10);
  text-decoration: underline;
}

.links-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: toRem(10);
}

.menu-icon {
  width: 20px;
  height: 20px;
}
