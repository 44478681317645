@import "./variables";

.sms {
  margin: toRem(10);
  height: 90vh;
}

.header {
  font-size: toRem(24);
  font-weight: 700;
  color: $header;
}

.sub-header {
  font-size: toRem(24);
  font-weight: 700;
  color: $grey-2;
  margin-top: toRem(20);
}

.logo {
  width: toRem(200);
}

.claim-style {
  font-size: toRem(14);
  font-weight: 400;
  color: $grey;
  margin-top: toRem(5);
  text-align: center;
}

.animation {
  margin-top: toRem(20);
}

.data-rate-style {
  font-size: toRem(14);
  font-weight: 600;
  color: $grey-1;
  .tbd {
    color: $red;
  }
}
