body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

button {
  cursor: pointer;
}

@import url("./components/styleSheet/inspection.scss");
@import url("./components/commonComponents/video/styles.scss");
@import url("./components/commonComponents/stylesheet/modal.scss");
