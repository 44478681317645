@import "./variables";

.inspection-flow {
  .margin-ver-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }

  .submit-review {
    font-weight: bold;
    font-size: larger;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .submit-page-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 36px;
    margin-top: 10px;
  }

  .text-center {
    text-align: center;
  }

  .success-text {
    font-weight: bold;
    font-size: 30px;
  }
}

.faq-list-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  padding-bottom: 10px;
  .faq-btn-list-selected {
    background-color: $blue;
    color: $white;
    padding: toRem(5) toRem(12.5);
    border-radius: toRem(20);
    margin-right: toRem(15);
    border: $white;
    cursor: pointer;
  }
  .faq-btn-list {
    background-color: transparent;
    color: $blue;
    padding: toRem(5) toRem(12.5);
    border-radius: toRem(20);
    margin-right: toRem(15);
    border: toRem(1) solid $blue;
    cursor: pointer;
  }
}
.main-container {
  -ms-overflow-style: none;
}

.faq-header-style {
  font-size: toRem(16);
  font-weight: 600;
  line-height: toRem(28);
  color: $grey-5;
}

.faq-content-style {
  font-size: toRem(16);
  font-weight: 400;
  line-height: toRem(28);
  text-align: left;
  color: $grey-2;
  padding-left: toRem(32.5);
  padding-right: toRem(22.5);
}

.faq-list-wrapper,
.main-container::-webkit-scrollbar {
  // display: none;
}

.font-bold {
  font-weight: bold;
}

.comment-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  justify-content: space-between;
  .comment-header {
    width: 250px;
    display: flex;
    justify-content: space-between;
  }
  #standard-multiline-static {
    margin-top: 20px;
  }
}

.faq-list-wrapper {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.faq-list-wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.video-image-wrapper {
  margin-bottom: 10px;
}
