@import "../../styleSheet/variables";

.arrow-list-header {
  margin-top: toRem(30);
  font-size: toRem(16);
  font-weight: 700;
  line-height: toRem(28);
}

.list {
  width: 100%;
  border: none;
  background-color: transparent;
  align-items: end;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  margin-top: toRem(10);
  color: #68758b;
}

.list-content {
  display: flex;
  align-items: end;
  gap: 15px;
}

.show-completed {
  width: toRem(20);
  .icon {
    color: $green;
  }
}

.sub-header-arrow-list {
  font-size: toRem(16);
  font-weight: 400;
  line-height: toRem(24);
  margin-top: toRem(10);
}
