@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

//colors
$header: #13223c;
$white: #ffffff;

$grey: #68758b;
$grey-1: #596981;
$grey-2: #3D515A;
$grey-3: #303340;
$grey-4: #13223C;
$grey-5: #3C4D6A;


$red: #b90000;
$blue: #0066cc;

$green: #047D26
