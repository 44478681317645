@import "components/styleSheet/variables";

:root {
  --font-size-root: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.outlined-button {
  width: 100%;
  margin-top: toRem(30);
  border-radius: toRem(6);
  background-color: transparent !important;
  border: toRem(1) solid $blue;
  color: $blue;
  width: toRem(250);
  height: toRem(44);
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.letter-spacing {
  letter-spacing: toRem(2);
}
