.img-dimension {
  height: 40px;
  width: 40px;
}

.background-black {
  background-color: black;
  height: 100vh;
}

.timer-style {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  top: 10%;
  z-index: 9;
}
