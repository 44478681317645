//color variable

$color_gray:#7D898D;
$color-white:#ffffff;
$background-dark:#303340;


//padding
$padding-left-20:20px;
$padding-right-10: 10px;
$margin-left-30:30px;

//font 
$font-small: 10px;