.image-modal-wrapper {
  background-color: transparent;
  padding: 0px;
  box-shadow: unset;
}

.image-close {
  svg {
    color: white;
  }
}
