.video-wrapper {
  display: flex;
  justify-content: center;
  height: 80vh;
  position: relative;
  margin-top: 10px;
  .video-component {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.container-webcam {
  background-color: black;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.camera-button-wrapper {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  button {
    background-color: transparent;
    color: white;
    border: 0px;
  }
}

.retake-button {
  display: flex;
  justify-content: space-evenly;
  /* width: 84%; */
  text-align: center;
  align-items: center;
  margin-top: 30px;
  .use-photo-btn {
    background-color: #0066cc;
    height: 34px;
    color: white;
    padding: 11px 24px;
    border: 1px solid #0066cc;
    border-radius: 6px;
    width: 120px;
    font-weight: 700;
    line-height: 8px;
  }
  .retake-btn {
    background-color: white;
    height: 34px;
    color: #0066cc;
    padding: 11px 24px;
    border: 1px solid #0066cc;
    border-radius: 6px;
    width: 120px;
    font-weight: 700;
    line-height: 8px;
  }
}
